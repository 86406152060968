






















































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import ActionButton from "@/components/ActionButton.vue";
import LoginService from "@/services/login.service";
import LoginError from "@/components/LoginError.vue";
@Component({
  components: {LoginError, ActionButton}
})
export default class ActivationPrompt extends Vue {
  @Prop() private username!: string;
  @Prop() private info!: string;
  @Prop({default: null}) private queryCode!: string;
  private error = '';
  private code = '';
  private symbols = /^(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])/;
  private newPassword = '';
  private repeatedPassword = '';
  private value = {
    repeat: true,
    new: true,
  };
  private passwordValid = true;
  private repeatValid = true;
  private loading = false;

  @Watch('queryCode', {immediate: true})
  private onQueryParamUpdate() {
    this.code = this.queryCode;
  }

  private async resend() {
    this.$emit('go-to:resend')
  }

  private async confirmActivationCode() {
    this.loading = true;
    try {
      await LoginService.confirmForgotPassword({username: this.username, code: this.code, password: this.newPassword});
      this.$emit('success');
    } catch (err) {
      this.error = (err as Error).message;
    } finally {
      this.loading = false;
    }
  }

}
