






















































































































































































































































































































































































































import {Vue, Component, Prop, Watch} from "vue-property-decorator";
import ActionButton from "@/components/ActionButton.vue";
import TextField from "@/components/TextField.vue";
import {Action, Getter, Mutation} from "vuex-class";
import {Gateway, Site} from "@/types";
import SiteService from "@/services/site.service";
import GatewayService from "@/services/gateway.service";
import EventBus from "@/services/eventbus.service";

enum GatewayWizardSteps {
  FindGateway = 1,
  GatewayFound = 2,
  GatewaySetup = 3,
  TestConnection = 4,
}

@Component({
  components: {TextField, ActionButton}
})
export default class GatewayWizard extends Vue {
  @Mutation private toggleKB: any;
  @Getter private accountId!: number;
  @Action private goHome!: any;
  @Prop() private sites!: Site[];
  @Prop() private site!: Site;
  @Prop() private gatewayStep!: number;
  private GatewayWizardSteps = GatewayWizardSteps;
  private activeStep: GatewayWizardSteps = GatewayWizardSteps.FindGateway;
  private testingConnection = false;
  private gatewayConnected = false;
  private loadingMountGateway = false;
  private gateway: Gateway = {} as Gateway;
  private selectedGateway: string | null = null;
  private localSites: Site[] = [];
  private gateways: Gateway[] = [];
  private gatewayConnectionSteps = 0;
  private instructions: Array<{[key: string]: string | number}> = [
    { step: 0, text:  'Make sure the antennas are attached to the gateway as shown in the in-box setup instructions.', img: require('@/assets/setup/gateway/antennas.png') },
    { step: 1, text:  'Plug the ethernet cable into the port on the front of the gateway.  Connect the other end of the ethernet cable to your Internet Router.', auxText: ' If your Internet Router is managed by an IT department, please check that the gateway is able to connect to the Internet.', img: require('@/assets/setup/gateway/ethernet.png')  },
    { step: 2, text:  'Attach the power supply to the power port on the front of the gateway.', img: require('@/assets/setup/gateway/power.png')  },
    { step: 3, text:  'Check that the LEDs light up.', img: require('@/assets/setup/gateway/lights.png')   },
  ];
  private imageLoaded: {[key:string]: boolean} = {};
  private zoomImg: {qr: boolean, eui: boolean} = {
    qr: true,
    eui: true,
  };
  private euiDialog = false;
  private qrDialog = false;
  private skipGatewayDialog = false;

  @Watch('gatewayStep', {immediate: true})
  private onStepChange() {
    if (this.gatewayStep > 0) {
      this.activeStep = this.gatewayStep;
      this.gatewayConnected = true;
    }
  }

  private async mounted() {
    this.gateways = await GatewayService.getGatewaysForProject();

    if (this.activeStep === GatewayWizardSteps.FindGateway) {
      if (this.gateways.length === 1) {
        this.gateway = this.gateways[0];
        this.selectedGateway = this.gateways[0].eui;
        this.activeStep = GatewayWizardSteps.GatewayFound;
        return;
      }
    }
  }

  private async mountGatewayAndStepForward() {
    this.loadingMountGateway = true;
    try {
      const mountedGateway = await GatewayService.mountGateway(this.site.id, this.gateway.id)
      EventBus.$emit('showSnackbar', {text: 'Gateway mounted', icon: 'mdi-content-save'});
      this.stepForward();
    } finally {
      this.loadingMountGateway = false;
    }

  }

  private async testConnection() {
    this.testingConnection = true;
    try {
      const response = await GatewayService.getGatewayStatus(this.gateway.id);
      if (response.status === 'connected') {
        this.gatewayConnected = true;
      }
    } catch (err) {
      this.gatewayConnected = false;
    } finally {
      this.testingConnection = false;
    }
  }

  private stepForward() {
    if (this.activeStep === GatewayWizardSteps.TestConnection && this.gatewayConnected) {
      this.$emit('complete:gateway-wizard', this.gateway)
    }
    this.activeStep++;

    if (this.activeStep === GatewayWizardSteps.TestConnection) {
      this.testConnection();
    }
  }

  private stepBack() {
    if (this.activeStep === GatewayWizardSteps.FindGateway) {
      this.$emit('return-to:site')
      return;
    }

    this.activeStep--;
  }

  private selectGateway(gateway: Gateway) {
    this.gateway = gateway;
    this.selectedGateway = gateway.eui;
  }


  private get gatewayImg() {
    return require('@/assets/laird_gateway.png')
  }


  private get gatewayDevEui(): string {
    return require('@/assets/setup/gateway/dev_eui.png')
  }

  private get gatewayQrCode(): string {
    return require('@/assets/setup/gateway/qr_code.png')
  }

  private get pageHeight(): string {
    return this.$vuetify.breakpoint.mdAndUp ? 'outer-md' : 'outer-sm';
  }
}
