import { appAxios } from '../app.axios';
import {Role, User} from "@/types";
import store from "@/store";

export class UserService {
  public async getUsers(): Promise<User[]> {
    const url = `/projects/${store.getters.accountId}/users`;
    return (await appAxios.get(url)).data;
  }
  public async getRoles(): Promise<Role[]> {
    const url = `/projects/${store.getters.accountId}/roles`;
    return (await appAxios.get(url)).data;
  }
  public async updateUser(projectId: number, userId: number, payload: { user: { first_name: string; last_name: string; alerts_enabled: boolean}, roles?: number[] | null; sites?: Array<{ site_id: number; roles: number[] }> }) {
    const url = `/projects/${store.getters.accountId}/users/${userId}`;
    return (await appAxios.put(url, payload)).data;
  }
  public async addUser(projectId: number, payload: { user: { first_name: string; last_name: string; alerts_enabled: boolean, email: string }, roles?: number[] | null; sites?: Array<{ site_id: number; roles: number[] }> }) {
    const url = `/projects/${store.getters.accountId}/users/add`;
    return (await appAxios.post(url, payload)).data;
  }
  public async removeUser(projectId: number, userId: number) {
    const url = `/projects/${store.getters.accountId}/users/${userId}`;
    return (await appAxios.delete(url)).data;
  }
  public async getCurrentUser(): Promise<User> {
    const url = '/users/me';
    return (await appAxios.get(url)).data;
  }
  public async getToken(token: string, tenant_id: number): Promise<string> {
    const url = '/users/token';
    return (await appAxios.post(url, { token, tenant_id })).data;
  }
}

export default new UserService();