import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Setup from "@/views/Setup.vue";
import Home from "@/views/Home.vue";
import DeviceManagement from "@/views/DeviceManagement.vue";
import LoginFlow from "@/views/login/LoginFlow.vue";
import ActivationFlow from "@/views/login/ResetFlow.vue";
import store from '../store';
import UserWizard from "@/views/wizards/UserWizard.vue";
import RecordManagement from "@/views/RecordManagement.vue";
import KnowledgeBase from "@/views/KnowledgeBase.vue";
import Users from "@/views/Users.vue";
import VueGtm from '@gtm-support/vue2-gtm';
import {getStage} from "@/util";
import SuperUserManagement from "@/views/admin/SuperUserManagement.vue";
import CustomerAccounts from "@/views/admin/CustomerAccounts.vue";
import SuperUserWizard from "@/views/wizards/SuperUserWizard.vue";
import NotFound from "@/views/NotFound.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";
import UserService from "@/services/user.service";
import ApplicationService from "@/services/application.service";

export enum RouteScope {
  Public,
  Private,
  Admin
}

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      scope: RouteScope.Private,
      accountIdRequired: true,
      includeWhenEmbedded: true,
      gtm: 'Home'
    }
  },
  {
    path: '/setup',
    name: 'setup',
    component: Setup,
    meta: {
      scope: RouteScope.Private,
      accountIdRequired: true,
      includeWhenEmbedded: true,
      gtm: 'Setup Wizard'
    }
  },
  {
    path: '/users',
    name: 'users',
    component: Users,
    meta: {
      scope: RouteScope.Private,
      accountIdRequired: true,
      gtm: 'User Management'
    }
  },
  {
    path: '/users/create',
    name: 'create-user',
    component: UserWizard,
    meta: {
      scope: RouteScope.Private,
      accountIdRequired: true,
      gtm: 'Create User'
    }
  },
  {
    path: '/devices',
    name: 'devices',
    component: DeviceManagement,
    meta: {
      scope: RouteScope.Private,
      accountIdRequired: true,
      includeWhenEmbedded: true,
      gtm: 'Device Management'
    }
  },
  {
    path: '/records',
    name: 'records',
    component: RecordManagement,
    meta: {
      scope: RouteScope.Private,
      accountIdRequired: true,
      includeWhenEmbedded: true,
      gtm: 'Record Management'
    }
  },
  {
    path: '/kb',
    name: 'knowledgebase',
    component: KnowledgeBase,
    meta: {
      scope: RouteScope.Private,
      accountIdRequired: false,
      includeWhenEmbedded: true,
      gtm: 'Knowledge Base'
    }
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: PrivacyPolicy,
    meta: {
      scope: RouteScope.Private,
      accountIdRequired: false,
      gtm: 'Privacy Policy'
    }
  },
  {
    path: '/admin/users',
    name: 'super-users',
    component: SuperUserManagement,
    meta: {
      scope: RouteScope.Admin,
      gtm: 'Super user management'
    }
  },
  {
    path: '/admin/users/create',
    name: 'create-super-user',
    component: SuperUserWizard,
    meta: {
      scope: RouteScope.Admin,
      gtm: 'Create super user'
    }
  },
  {
    path: '/admin/accounts',
    name: 'customer-accounts',
    component: CustomerAccounts,
    meta: {
      scope: RouteScope.Admin,
      gtm: 'Customer accounts'
    }
  },
  // {
  //   path: '/login',
  //   name: 'login',
  //   component: LoginFlow,
  //   meta: {
  //     scope: RouteScope.Public,
  //     gtm: 'Login'
  //   }
  // },
  // {
  //   path: '/reset',
  //   name: 'reset',
  //   component: ActivationFlow,
  //   meta: {
  //     scope: RouteScope.Public,
  //     gtm: 'Reset Password'
  //   }
  // },
  {
    path: '/404',
    alias: '*',
    component: NotFound,
    meta: {
      scope: RouteScope.Public,
      accountIdRequired: false,
      gtm: 'Page Not Found'
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  routes
});

if (getStage() === 'prod') {
  Vue.use(VueGtm, {
    id: 'GTM-PKM9LKK',
    debug: false,
    vueRouter: router
  });
}

router.beforeEach(async (to, from, next) => {
  // Are we looking at a protected route?
  if (!to.matched.some((record) => record.meta.scope === RouteScope.Public)) {
    // Is the route allowed when the application is embedded?
    if (!to.matched.some((record) => record.meta.includeWhenEmbedded) && process.env.VUE_APP_EMBED_APPLICATION) {
      next({name: 'home'});
      return;
    }
    // Everything behind authentication //
    let localStorageToken = localStorage.getItem('token');
    const querystringParams = new URLSearchParams(window.location.search);
    const token = querystringParams.get('token');
    const tenantId = querystringParams.get('tenantId');

    if (!localStorageToken && process.env.VUE_APP_EMBED_APPLICATION && token) {
      localStorageToken = await UserService.getToken(token, Number(tenantId)!);
      await store.dispatch('userLogin', localStorageToken)
    }

    if (localStorageToken) {
      store.commit('setToken', localStorage.getItem('token'));

      // This works around Vue not waiting for refreshUser dispatch to complete
      const accountId = localStorage.getItem('accountId');
      const accountCode = localStorage.getItem('accountCode');
      if (accountId && accountCode) {
        store.commit('setAccountId', {accountId, accountCode});
        const accountManaged = localStorage.getItem('accountManaged') === 'true';
        store.commit('setAccountManaged', accountManaged);
      }
      await store.dispatch('refreshUser');
    }
    // Always set pre-login route in case of 401
    store.commit('setPreLoginRoute', to);
    
    // // Check we actually have an API token otherwise hit the login
    // if (!store.getters.refrigerationToken && (!localStorageToken || localStorageToken === 'null')) {
    //   next({name: 'login'});
    //   return;
    // }

    if (to.matched.some((record) => record.meta.scope === RouteScope.Admin)) { // Are we on a admin route, if so do we have permissions, if not goto home
      if (
        !store.getters.permissions ||
        !store.getters.permissions.length ||
        !store.getters.permissions.some((p: string) => p.includes('administration:'))
      ) {
        next({name: 'home'});
        return;
      }
    } else if (!store.getters.accountId) { // We are not on Admin route, if we don't have an account id, we are an admin and are on an accountId route -> goto customer accounts
      if (
        store.getters.permissions &&
        store.getters.permissions.length &&
        store.getters.permissions.some((p: string) => p.includes('administration:'))
      ) {
        if (to.matched.some((record) => !!record.meta.accountIdRequired)) {
          next({name: 'customer-accounts'});
          return;
        }
      }
    }

    // Check if theme is already loaded, if so, ignore this. Also checks for application attributes.
    if (router.app.$vuetify && !store.getters.themeLoaded) {
      await store.dispatch('fetchApplicationAttributes');

      const theme = await ApplicationService.getTheme();
      router.app.$vuetify.theme.themes.dark = { ...router.app.$vuetify.theme.themes.dark, primary: theme.primary, secondary: theme.secondary };
      store.commit('setThemeLoaded', true);
    }
  }
  next();
});

export default router;
