import { render, staticRenderFns } from "./KnowledgeBase.vue?vue&type=template&id=19cd2ca5&scoped=true&"
import script from "./KnowledgeBase.vue?vue&type=script&lang=ts&"
export * from "./KnowledgeBase.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19cd2ca5",
  null
  
)

export default component.exports