



















































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {Asset, DeviceEvent, MonitoringPoint, Room, Site} from '../types';
import DeviceService from "@/services/device.service";
import {Getter} from "vuex-class";
import Loader from "@/components/Loader.vue";
import DateRangeSelector from "@/components/DateRangeSelector.vue";
import EventsService from "@/services/events.service";
import SiteService from "@/services/site.service";
import dayjs from "dayjs";

@Component({
  components: {DateRangeSelector, Loader}
})
export default class DeviceMetrics extends Vue {
  @Getter private accountId!: number;
  @Prop() private selectedSite!: { [key: string]: any };
  private alerts: any = [];
  private weather: any = [];
  private site: Site = {} as Site;
  private device: { [key: string]: string | number | DeviceEvent } = { id: 0, name: '', status: 'ok', deviceId: '', assetId: 0, gatewayId: 0, siteId: 0, lastEvent: { timestamp: new Date(), temperature: '0', humidity: 0, battery: '80-100%', alerts: null } };
  private asset: Asset = { id: 0, name: '', siteId: 0, manufacturer: '', assetType: '', configuration: { minTemp: 0, maxTemp: 0, alertThreshold: 0, }, monitoringPoints: [] };
  private monitoringPoint: MonitoringPoint = {} as MonitoringPoint;
  private room: Room = {} as Room;
  private deviceData: DeviceEvent[] = [];
  private showDevice = true;
  private range: { range: { from: Date; to: Date; }, span: string } = {
    range: { from: new Date(new Date().setDate(new Date().getDate() + 1)), to: new Date() },
    span: 'week'
  }
  private thresholds: {[key: string]: number} = {};
  private loading = false;
  private max: number | null = null;
  private min: number | null = null;
  private gradients: string[] = [];
  private overlayWeather = false;
  private overlayAmbient = false;
  private openAlerts: any[] = [];
  private alertTable: { rows: []; headers: { text: string; value: string }[] } = {
    rows: [],
    headers: [
      { text: "Alert occurred at", value: "alert_timestamp" },
      { text: "Alert cleared at", value: "cleared_timestamp" },
      { text: "Current status", value: "status" },
      { text: "Type of alert", value: "type" },
    ]
  }

  private tempChartSeries: any = [{
    name: "Temperature",
    data: []
  }];
  private tempChartOpts: Record<string, any> = {
    noData: {
      text: 'No data for this period',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
    },
    colors: [this.$vuetify.theme.currentTheme.secondary],
    chart: {
      theme: 'dark',
      animations: {
        enabled: false
      },
      foreColor: 'rgba(255,255,255,0.5)',
      type: 'line',
      toolbar: {
        show: false,
      }
    },
    stroke: {
      width: 3,
      curve: 'smooth'
    },
    xaxis: {
      type: 'datetime',
      min: new Date(new Date(this.range.range.from).setHours(12,0,0,0)).getTime(),
      tooltip: {
        enabled: true,
        backgroundColor: 'rgba(30,30,30,0.8)',
        formatter: function (dt: any, opts: any): string {
          return dayjs(new Date(dt)).format('YYYY-MM-DD HH:mm:ss')
        }
      },
    },
    tooltip: {
      theme: 'dark',
      x: {
        show: false,
      },
      y: {
        formatter: function(value: any, opts: any) {
          if (opts.w.config.series[opts.seriesIndex].name.toLowerCase() === 'ambient') {
            return '<div style="width: 100%; color: #f1c148; text-align: center; padding-bottom: 2px">Room</div>' + 'Temperature: ' + value + '°C';
          }
          if (opts.w.config.series[opts.seriesIndex].name.toLowerCase() === 'weather') {
            return '<div style="width: 100%; color: #48a6f1; text-align: center; padding-bottom: 2px">Outdoor</div>' + 'Temperature: ' + value + '°C';
          }
          if (opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex] && opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex].alertStatus && opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex].alertCleared) {
            return 'Temperature: ' + value + '°C';
            // return '<div style="width: 100%; color: #00b17e; text-align: center; padding-bottom: 2px">Cleared alert</div>' + 'Temperature: ' + value + '°C';
          }
          if (opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex] && opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex].alertStatus) {
            return '<div style="width: 100%; color: #f2243f; text-align: center; padding-bottom: 2px">Open alert</div>' + 'Temperature: ' + value + '°C';
          }
          return 'Temperature: ' + value + '°C';
        }
      }
    },
    dataLabels: {
      enabled: false
    },
    yaxis: {
      min: 0,
      max: 20,
      tickAmount: 7,
      labels: {
        formatter: function(val: number) {
          return `${val}°C`;
        },
      },
    },
  }
  private humidityChartSeries: any[] = [{
    name: 'Humidity',
    data: []
  }];
  private humidityChartOpts: any = {
    noData: {
      text: 'No data for this period',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
    },
    colors: [this.$vuetify.theme.currentTheme.secondary],
    chart: {
      animations: {
        enabled: false
      },
      foreColor: 'rgba(255,255,255,0.5)',
      type: 'line',
      toolbar: {
        show: false,
      }
    },
    stroke: {
      width: 3,
      curve: 'smooth'
    },
    xaxis: {
      type: 'datetime',
      min: new Date(new Date(this.range.range.from).setHours(12,0,0,0)).getTime(),
      tooltip: {
        enabled: true,
        backgroundColor: 'rgba(30,30,30,0.8)',
        formatter: function (dt: any, opts: any): string {
          return dayjs(new Date(dt)).format('YYYY-MM-DD HH:mm:ss')
        }
      },
    },
    tooltip: {
      theme: 'dark',
      x: {
        show: false,
      },
      y: {
        formatter: function(value: any, opts: any) {
          return 'Humidity: ' + value + '%';
        }
      }
    },
    yaxis: {
      tickAmount: 5,
      labels: {
        formatter: function(val: number) {
          return `${val}%`;
        },
      },
    },
  }

  @Watch('overlayWeather')
  private async showWeatherOverlay() {
    if (this.overlayWeather) {
      await this.zoomRange(this.range)
    } else {
      // clear weather data
      this.weather = [];
      await this.zoomRange(this.range)
    }
  }

  @Watch('overlayAmbient')
  private async showAmbientOverlay() {
    if (this.overlayAmbient) {
      await this.zoomRange(this.range)
    } else {
      // clear weather data
      this.weather = [];
      await this.zoomRange(this.range)
    }
  }

  @Watch('selectedSite')
  private async onSiteSelect() {
    await this.initialDataLoad();
  }

  private async mounted() {
    await this.initialDataLoad();
    await this.zoomRange(this.range);
  }

  private showConfiguration() {
    this.showDevice = false;
  }

  private async zoomRange(range: { range: { to: Date; from: Date; }, span: string }) {
    this.gradients = [];
    this.range = range;
    if (this.$refs && this.$refs.tempChart) {
      await this.setData({ range: { to: new Date(new Date(this.range.range.to).setHours(23, 59, 59, 999)), from: new Date(new Date(this.range.range.from).setHours(0, 0, 0, 999))}, span: range.span });

      // HACK: For some reason we get script delays if we do not zoom in nextTick (This only impacts when called from component event not initial load)
      this.$nextTick(() => {
        if (this.$refs.tempChart) {
          (this.$refs.tempChart as any).zoomX(new Date(range.range.from).getTime(), new Date(range.range.to).getTime());
          (this.$refs.humidityChart as any).zoomX(new Date(range.range.from).getTime(), new Date(range.range.to).getTime());

          (this.$refs.tempChart as any).clearAnnotations();

          for (const alert of this.openAlerts) {
            (this.$refs.tempChart as any).addXaxisAnnotation({
              x: new Date(alert.timestamp).getTime() > new Date(range.range.from).getTime() ? new Date(alert.timestamp).getTime() : range.range.from.getTime(),
              x2: new Date(alert.timestamp).getTime() < new Date(range.range.to).getTime() ? range.range.to.getTime() : new Date(alert.timestamp).getTime(),
              fillColor: 'rgba(242,36,63,0.6)',
              borderColor: '#f2243f',
            })
          }
          for (const alert of this.alerts) {
            (this.$refs.tempChart as any).addXaxisAnnotation({
              x: new Date(alert.timestamp).getTime(),
              x2: new Date(alert.alert_cleared?.created_at).getTime(),
              fillColor: 'rgba(242,36,63,0.6)',
              borderColor: '#f2243f',
            })
          }
        }
      });
    }
  }

  private async setRange(range: { range: { to: Date; from: Date; }, span: string }) {
    this.alerts = [];

    if (this.$refs && this.$refs.tempChart && range.span === 'month') {
      this.tempChartOpts = {
        ...this.tempChartOpts,
        xaxis: {
          type: 'datetime',
          min: new Date(new Date(this.range.range.from).setHours(0, 1, 0, 0)).getTime(),
        }
      }
    this.humidityChartOpts = {
        ...this.humidityChartOpts,
        xaxis: {
          type: 'datetime',
          min: new Date(new Date(this.range.range.from).setHours(0, 1, 0, 0)).getTime(),
        }
      }
    }
  }

  private async setData(range: { range: { to: Date; from: Date; }, span: string }) {
    const data = await EventsService.getHistoricalDeviceEvents(this.accountId, this.selectedSite.monitoringPoint.id, {since: range.range.from.getTime(), before: range.range.to.getTime()});
    await this.getAlerts({ since: range.range.from, before: range.range.to });

    const getAlertType = (type: string) => {
      const types: Record<string, string> = {
        'temperature_alert': 'Temperature alert',
        'battery_alert': 'Battery alert',
        'nodata_alert': 'No data alert',
      }
      return types[type];
    }

    const alerts = [];
    const alertsForPeriod = this.alerts.map((a: { timestamp: string; alert_cleared: Record<string, any>; alert_type: string }) => {
      return {
        alert_timestamp: a.timestamp,
        cleared_timestamp: a.alert_cleared ? a.alert_cleared.created_at : '',
        status: a.alert_cleared ? 'cleared' : 'open',
        type: getAlertType(a.alert_type)
      }
    })
    const openAlerts: any[] = [];
    for (const alert of this.openAlerts) {
      if (new Date(alert.timestamp).getTime() < range.range.from.getTime()) {
        openAlerts.push({
          alert_timestamp: alert.timestamp,
          cleared_timestamp: '',
          status: 'open',
          type: getAlertType(alert.alert_type)
        })
      }
    }
    Vue.set(this.alertTable, 'rows', [...alertsForPeriod, ...openAlerts]);
    Vue.set(this, 'deviceData', data);
    this.gradients = ['temperature'];

    const tempData = data.map((point: DeviceEvent) => {
      return {x: new Date(point.timestamp).getTime(), y: point.temperature, alertStatus: point.alerts.length > 0, alertCleared: point.alerts.every((a: any) => !!a.alert_cleared)}
    })
    this.tempChartSeries = [
      { name: "Temperature", data: tempData }
    ];
    const humidityData = this.deviceData.map((point) => {
      return {x: new Date(point.timestamp).getTime(), y: point.humidity }
    })
    this.humidityChartSeries = [
      { name: 'Humidity', data: humidityData }
    ];

    const thresholds = {
      minTemp: Number(this.monitoringPoint.attributes.find((a) => a.key === 'minTemp')?.value),
      maxTemp: Number(this.monitoringPoint.attributes.find((a) => a.key === 'maxTemp')?.value),
    };
    Vue.set(this, 'thresholds', thresholds);

    const min = Math.min(...this.tempChartSeries[0].data.map((d: {x: number, y: number}) => d.y));
    const max = Math.max(...this.tempChartSeries[0].data.map((d: {x: number, y: number}) => d.y));

    this.min = Math.min(this.getMinY(min), this.getMinY(this.thresholds.minTemp));
    this.max = Math.max(this.getMaxY(max), this.getMaxY(this.thresholds.maxTemp));

    if (this.overlayAmbient) {
      const ambientSensor = this.selectedSite.room.assets.find((a: Asset) => a.assetType === 'room');
      if (ambientSensor) {
        const ambient = await EventsService.getHistoricalDeviceEvents(this.accountId, ambientSensor.monitoringPoints[0].id, {
          since: range.range.from.getTime(),
          before: range.range.to.getTime()
        });

        this.tempChartSeries = [...this.tempChartSeries, {
          name: "Ambient",
          data: ambient.map((w: DeviceEvent) => {
            return {x: new Date(w.timestamp).getTime(), y: w.temperature}
          }),
        }]
        const ambientMin = Math.ceil(Math.min(...ambient.map((w: DeviceEvent) => Number(w.temperature))));
        const ambientMax = Math.ceil(Math.max(...ambient.map((w: DeviceEvent) => Number(w.temperature))));
        this.min = this.min < ambientMin ? this.min : ambientMin;
        this.max = this.max > ambientMax ? this.max : ambientMax;
        this.gradients = [...this.gradients, 'ambient'];
      }
    }

    if (this.overlayWeather) {
      const weather = await SiteService.getWeatherForSite(this.selectedSite.site.id, {
        since: Math.round(Number(range.range.from.getTime()) / 1000),
        before: Math.round(Number(range.range.to.getTime()) / 1000)
      })
      this.tempChartSeries = [...this.tempChartSeries, {
        name: "Weather",
        data: weather.map((w: { timestamp: Date; temperature: number }) => {
          return {x: new Date(w.timestamp).getTime(), y: w.temperature}
        }),
      }]

      const weatherMin = Math.ceil(Math.min(...weather.map((w: DeviceEvent) => Number(w.temperature))));
      const weatherMax = Math.ceil(Math.max(...weather.map((w: DeviceEvent) => Number(w.temperature))));
      this.min = this.min < weatherMin ? this.min : weatherMin;
      this.max = this.max > weatherMax ? this.max : weatherMax;
      this.gradients = [...this.gradients, 'weather'];
    }
    // if (this.openAlerts.length) {
    //
    //   this.tempChartSeries = [...this.tempChartSeries, {
    //     name: "Alerts",
    //     data: this.openAlerts.flatMap((a: { timestamp: Date; temperature: number }) => {
    //       return [
    //         {
    //           x: new Date(a.timestamp).getTime() > new Date(range.range.from).getTime() ? new Date(a.timestamp).getTime() : new Date(range.range.from.getTime() + diff).getTime(),
    //           y: a.temperature},
    //         {
    //           x: Date.now(),
    //           y: a.temperature
    //         }]
    //     }),
    //   }]
    //   this.gradients = [...this.gradients, 'alerts'];
    // }

    this.tempChartOpts = {
      ...this.tempChartOpts,
      annotations: {
        ...this.tempChartOpts.annotations,
        xaxis: [
        ...this.alerts.map((a: any) => {
          return {
            x: new Date(a.timestamp).getTime(),
            ...( a.alert_cleared && { ...{ x2: new Date(a.alert_cleared.created_at).getTime() }}),
            fillColor: 'rgba(242,36,63,0.6)',
            borderColor: '#f2243f',
          }
        }),
        ...this.openAlerts.map((a: any) => {
            return {
              x: new Date(a.timestamp).getTime() > new Date(range.range.from).getTime() ? new Date(a.timestamp).getTime() : range.range.from.getTime(),
              x2: new Date(a.timestamp).getTime() < new Date(range.range.to).getTime() ? range.range.to.getTime() : new Date(a.timestamp).getTime(),
              fillColor: 'rgba(242,36,63,0.6)',
              borderColor: '#f2243f',
            }
          })
        ],
      },
      fill: {
        type: this.gradients.map((g) => 'gradient'),
        gradient: {
          type: 'vertical',
          shadeIntensity: 1,
          opacityFrom: 1,
          opacityTo: 1,
          colorStops: [[
            {
              offset: 0,
              color: '#f2243f',
              opacity: 1
            },
            {
              offset: 50,
              color: "#32374b",
              opacity: 1
            },
            {
              offset: 100,
              color: "#48a6f1",
              opacity: 1
            },
          ],
            this.gradients[1] && this.gradients[1] === 'ambient' ? [{
              offset: 0,
              color: "#f1c148",
              opacity: 1
            }] : this.gradients[1] && this.gradients[1] === 'weather' ? [{
              offset: 0,
              color: "#48a6f1",
              opacity: 1
            }] : [],
            this.gradients[2] && this.gradients[2] === 'weather' ? [{
              offset: 0,
              color: "#48a6f1",
              opacity: 1
            }] : this.gradients[2] && this.gradients[2] === 'ambient' ? [{
              offset: 0,
              color: "#f1c148",
              opacity: 1
            }] : [],
            this.gradients[3] && this.gradients[3] === 'alerts' ? [{
              offset: 0,
              color: "rgba(255,255,255,0)",
              opacity: 1
            }] : this.gradients[3] && this.gradients[3] === 'alerts' ? [{
              offset: 0,
              color: "rgba(255,255,255,0)",
              opacity: 1
            }] : [],
          ]
        }
      },
      yaxis: {
        tickAmount: Math.max(this.getMaxY(max), this.getMaxY(this.thresholds.maxTemp) - (Math.min(this.getMinY(min), this.getMinY(this.thresholds.minTemp)))) / 3,
        min: this.min,
        max: this.max,
        labels: {
          formatter: function (val: number) {
            return `${Math.round(val)}°C`;
          },
        }
      }
    }
  }

  private async getAlerts(range: { since: Date; before: Date; }) {
    let alerts = await SiteService.getAlertsForLocation(this.selectedSite.monitoringPoint.id,
        {
          since: range.since.getTime(),
          before: range.before.getTime()
        });
    if (alerts) {
      this.alerts = alerts;
    }
  }

  private async initialDataLoad() {
    this.loading = true;
    try {
      const from = dayjs().subtract(7, 'days').toDate();
      this.site = this.selectedSite.site;
      const device = await DeviceService.getDevice(Number(this.selectedSite.device.id));
      await this.getAlerts({ since: from, before: new Date() });

      if (device?.id) {

        Vue.set(this, 'device', device);
        Vue.set(this, 'room', this.selectedSite.room)
        Vue.set(this, 'asset', this.selectedSite.asset);
        Vue.set(this, 'monitoringPoint', this.selectedSite.monitoringPoint)
        this.openAlerts = await SiteService.getAlertsForLocation(this.monitoringPoint.id, undefined, true)
        await this.setData({ range: { to: new Date(), from: from}, span: 'week'})
      }
      this.tempChartOpts = {
        ...this.tempChartOpts,
        legend: {
          show: false
        },
        annotations: {
          position: 'front',
          yaxis: [
            {
              y: this.thresholds.minTemp,
              borderColor: '#48a6f1',
              strokeDashArray: 4,
              label: {
                offsetY: 15,
                borderColor: '#48a6f1',
                style: {
                  color: '#ffffff',
                  background: "#48a6f1"
                },
                text: `${this.thresholds.minTemp} °C`
              }
            },
            {
              y: this.thresholds.maxTemp,
              borderColor: '#f2243f',
              strokeDashArray: 4,
              label: {
                offsetY: -3,
                borderColor: '#f2243f',
                style: {
                  color: '#ffffff',
                  background: "#f2243f"
                },
                text: `${this.thresholds.maxTemp} °C`
              }
            }
          ],
        },
        fill: {
          type: this.gradients.map((g) => 'gradient'),
          gradient: {
            type: 'vertical',
            shadeIntensity: 1,
            opacityFrom: 1,
            opacityTo: 1,
            colorStops: [[
              {
                offset: 0,
                color: '#f2243f',
                opacity: 1
              },
              {
                offset: 50,
                color: "#32374b",
                opacity: 1
              },
              {
                offset: 100,
                color: "#48a6f1",
                opacity: 1
              },
            ],
            this.gradients[1] && this.gradients[1] === 'ambient' ? [{
                offset: 0,
                color: "#f1c148",
                opacity: 1
              }] : this.gradients[1] && this.gradients[1] === 'weather' ? [{
                offset: 0,
                color: "#48a6f1",
                opacity: 1
              }] : [],
              this.gradients[2] && this.gradients[2] === 'weather' ? [{
                offset: 0,
                color: "#48a6f1",
                opacity: 1
              }] : this.gradients[2] && this.gradients[2] === 'ambient' ? [{
                offset: 0,
                color: "#f1c148",
                opacity: 1
              }] : [],
            ]
          }
        }
      }

    } finally {
      this.loading = false;
    }
  }

  private formatTimestamp(timestamp: string) {
    if (!timestamp.length) {
      return '';
    }
    return dayjs(new Date(timestamp)).format('YYYY-MM-DD HH:mm:ss')
  }

  private getMaxY(temperature: number) {
    return Math.ceil(temperature / 3) * 3
  }

  private getMinY(temperature: number) {
    return Math.floor(temperature / 3) * 3
  }

  private get hasAmbientSensor() {
    return this.selectedSite.room.assets.some((a: Asset) => a.assetType === 'room') && this.selectedSite.asset.assetType !== 'room';
  }
}

