






































































































































































































































































































































































































































































































































































































































































































































































import {Vue, Component, Prop, Watch} from "vue-property-decorator";
import ActionButton from "@/components/ActionButton.vue";
import TextField from "@/components/TextField.vue";
import Select from "@/components/Select.vue";
import {Action, Getter, Mutation} from "vuex-class";
import {ApplicationAttribute, Asset, Device, MonitoringPoint, Room, Site} from "@/types";
import SiteService from "@/services/site.service";
import DeviceService from "@/services/device.service";
import EventBus from "@/services/eventbus.service";
import {throttle} from "@/classes/utils";
import validation from "@/classes/validation";
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader'

enum InputModes {
  QrScan = 0,
  KeyIn = 1
}
enum DeviceWizardSteps {
  FindDevice = 1,
  DeviceFound = 2,
  SelectAsset = 3,
  DeviceInformation = 4,
  PlaceDevice = 5,
  TestConnection = 6,
  ScanBarcode = 7,
  ConfirmAssign = 8,
  SelectRoom = 9
}

@Component({
  components: {Select, TextField, ActionButton, QrcodeStream, QrcodeDropZone, QrcodeCapture}
})
export default class DeviceWizard extends Vue {
  @Getter private accountId!: number;
  @Getter private applicationAttributes!: ApplicationAttribute[];
  @Action private showToastError!: any;
  @Action private goHome!: any;
  @Prop() private savedDevices!: Device[];
  @Prop() private sites!: Site[];
  @Prop() private site!: Site;
  @Mutation private toggleKB: any;

  private DeviceWizardSteps = DeviceWizardSteps;
  private activeStep: DeviceWizardSteps = DeviceWizardSteps.FindDevice;
  private instructions: Array<{[key: string]: string | number}> = [
    { step: 0, img: require('@/assets/setup/sensor/battery.png'), text: `Insert two Lithium AA batteries into the sensor` },
    { step: 1, img: require('@/assets/setup/sensor/place.png'), text: 'Install the sensor in a suitable location, ensuring it is away from outlet grills, and securely fitted.' }
  ];
  private placementSteps = 0;

  /*Barcode*/
  private InputModes = InputModes;
  private eui: string | null = null;
  private validEui = false;
  private loadingCamera = false;
  private selectedMode = InputModes.QrScan;
  private onDecode = throttle((result: string) => { this.displayResult(result) }, 15000);
  private searching = false;
  private assigning = false;
  private loadingScanResult = false;
  private checkingPermission = false;
  /*End barcode*/

  private foundWithBarcode = false;
  private selectedDevice: string | undefined = '';
  private selectedAsset: number | null = null;

  private loadingAsset = false;
  private testingConnection = false;
  private deviceConnected = false;
  private selectedAlertThreshold = 15;

  private rooms: Room[] = [];
  private room: Room | null = null;
  private createdRoom: Room | null = null;
  private selectedRoom: number | null = null;
  private showRoomCreation = false;
  private loadingRoom = false;
  private roomName: string | null = '';

  private assets: Asset[] | undefined = [];
  private newAsset: { [key:string]: string | null } = { name: '', manufacturer: ''};
  private asset: Asset | null = null;
  private createdAsset: Asset | null = null;
  private selectedAssetType = '';
  private showAssetCreation = false;
  private assetTypes: Array<{ assetType: string; icon: string; }> = [
    { assetType: 'refrigerator', icon: 'mdi-fridge-outline' },
    { assetType: 'freezer', icon: 'mdi-snowflake' },
    { assetType: 'room', icon: 'mdi-floor-plan' },
  ];
  private euiDialog: { [key: number]: boolean } = {};
  private zoomImg = {
    eui: true,
  };
  private loadingMonitoringPoint = false;
  private monitoringPoint: MonitoringPoint = {} as MonitoringPoint;
  private configuration: any = {};
  private createdMonitoringPoint: MonitoringPoint = {} as MonitoringPoint;
  private validTemps = false;

  private loadingMounting = false;
  private devices: Device[] = [];
  private device: Device = {} as Device;
  private alertThresholds: Array<{ text: string, value: number }> = [
    { text: '0 min', value: 0 },
    { text: '15 min', value: 15 },
    { text: '30 min', value: 30 },
    { text: '1 hr', value: 60 },
    { text: '2 hr', value: 120 }
  ]
  private suppliers: string[] = [
    'Polar',
    'Nisbets Essentials',
    'Williams',
    'Foster',
    'Gamko',
    'True',
    'Adande',
    'Zoin',
    'Gram',
    'Blizzard',
    'Autonumis',
    'Kubus/Designline (drop in units)',
    'Lincat',
    'IMC',
    'Autonumis',
    'Mondial Elite',
    'Smeg',
    'Hoshizaki',
    'Infrico',
    'Crystal',
    'Artica',
  ]

  @Watch('configuration.minTemp')
  @Watch('configuration.maxTemp')
  private async onConfigChange() {
    await this.$nextTick();
    if (this.$refs && this.$refs.tempForm) {
      (this.$refs.tempForm as any).validate();
    }
  }

  private async mounted() {
    const applicationManufacturers = this.applicationAttributes.find((attrs) => attrs.id === 'manufacturers');
    if (applicationManufacturers && applicationManufacturers.value) {
      const supplierList = applicationManufacturers.value.split(',');
      this.suppliers = supplierList.map((s) => s.trim());
    }

    if (this.savedDevices.length) {
      this.devices = this.savedDevices;
    } else {
      await this.saveDevices()
    }

    if (this.activeStep === DeviceWizardSteps.FindDevice) {
      if (this.devices.length === 1) {
        this.activeStep = DeviceWizardSteps.DeviceFound;
        this.selectedDevice = this.devices[0].eui;
        this.device = this.devices[0];
      }
    }
  }

  private async saveDevices(device?: Device) {
    if (device) {
      this.devices.push(device);
    }
    this.devices = await DeviceService.getDevices();
    this.$emit('save:devices', this.devices)
  }

  private async getRooms() {
    if (this.site.rooms && this.site.rooms.length) {
      Vue.set(this, 'rooms', this.site.rooms);
    } else {
      this.showRoomCreation = true;
    }
  }

  private async getAssets(type: string, selectedRoom?: number) {
    this.selectedAssetType = type;
    if (selectedRoom) {
      const room = this.site.rooms?.find((r) => r.id === selectedRoom);
      if (room) {
        this.assets = room.assets.filter((c: Asset) => {
          if (c.assetType === type) {
            return c;
          }
        })

        if (!room.assets.filter((asset: Asset) => asset.assetType === type).length) {
          this.asset = null;
          this.showAssetCreation = true;
        }
      }
    }
  }

  private async selectRoom(r: Room) {
    this.selectedRoom = r.id;
    if (r.name) {
      this.room = r;
    }
    this.activeStep = DeviceWizardSteps.SelectAsset;
  }

  private async selectAsset(a: Asset) {
    this.selectedAsset = a.id;
    if (a.name) {
      this.asset = a;
    }
  }

  private stepForward() {
    if (this.activeStep === DeviceWizardSteps.TestConnection && this.deviceConnected) {
      this.$emit('complete:device-wizard')
    }

    if (this.activeStep === DeviceWizardSteps.DeviceFound) {
      this.getRooms();
      this.activeStep = DeviceWizardSteps.SelectRoom;
      return;
    }

    this.activeStep++;
    if (this.activeStep === DeviceWizardSteps.TestConnection) {
      this.testConnection();
    }
  }

  private selectDevice(d: Device) {
    this.device = d;
    this.selectedDevice = d.eui;
    this.stepForward();
  }

  private async deleteEmptyRoom(roomId: number) {
    try {
      await SiteService.deleteSite(roomId);
      this.rooms = this.rooms.filter((r) => r.id !== roomId)
    } catch (e) {
      const err = e as Error;
      this.showToastError(err.message)
    } finally {
      if (!this.rooms.length) {
        this.showRoomCreation = true;
      }
    }
  }

  private async saveRoomAndStepForward() {
    try {
      if (!this.room?.id) {
        this.loadingRoom = true;
        this.createdRoom = await DeviceService.createRoom({
          name: this.roomName,
          siteId: this.site.id,
          lat: this.site.coords[0],
          lng: this.site.coords[1],
        })
        Vue.set(this, 'room', this.createdRoom)
        const site = await SiteService.getSites(this.accountId, 4, this.site.id)
        const room = site[0].rooms?.find((r) => r.id === this.createdRoom?.id);
        if (room) {
          this.rooms.push(room);
        }
        EventBus.$emit('showSnackbar', {text: 'Room created', icon: 'mdi-content-save'});
      }
      this.activeStep = DeviceWizardSteps.SelectAsset;
    } catch (e) {
      const err = e as Error;
      this.showToastError(err.message)
    } finally {
      this.loadingRoom = false;
    }
  }

  private async saveLocationAndStepForward() {
    try {
      if (!this.asset?.id) {
        this.loadingAsset = true;
        this.createdAsset = await DeviceService.createAssetLocation({
          name: this.assetName,
          manufacturer: this.assetManufacturer,
          roomId: this.createdRoom?.id || this.room?.id || undefined,
          siteId: this.site.id,
          lat: this.site.coords[0],
          lng: this.site.coords[1],
          assetType: this.selectedAssetType
        })
        EventBus.$emit('showSnackbar', {text: 'Location created', icon: 'mdi-content-save'});
      }
      this.stepForward();
    } catch (e) {
      const err = e as Error;
      this.showToastError(err.message)
    } finally {
      this.loadingAsset = false;
    }
  }

  private async mountDeviceAndStepForward() {
    this.loadingMounting = true;
    try {
      if (!this.monitoringPoint?.id) {
        this.loadingMonitoringPoint = true;
        this.createdMonitoringPoint = await DeviceService.createMonitoringPoint({
          name: 'Temperature', // TODO: Name based on sensor type
          assetId: this.createdAsset ? this.createdAsset.id : this.asset?.id,
          lat: this.site.coords[0],
          lng: this.site.coords[1],
          configuration: {
            minTemp: Number(this.configuration.minTemp),
            maxTemp: Number(this.configuration.maxTemp),
            alertThreshold: this.selectedAlertThreshold
          }
        })
        await DeviceService.mountDevice(this.createdMonitoringPoint.id, this.device.id)
        EventBus.$emit('showSnackbar', {text: 'Sensor mounted', icon: 'mdi-content-save'});
      }
      this.stepForward();
    } catch (e) {
      const err = e as Error;
      this.showToastError(err.message)
      throw e;
    } finally {
      this.loadingMounting = false;

    }
  }

  private async testConnection() {
    this.testingConnection = true;
    try {
      const messages = await DeviceService.getDeviceActivity(this.device.device_version.slug, this.device.chip.identifier)
      if (messages && messages.count > 0) {
        this.deviceConnected = true;
      }
    } finally {
      this.testingConnection = false;
    }
  }

  private goBackFromAssetSelection() {
    this.showAssetCreation = false;
    this.selectedAsset = null;
    this.asset = null;
    this.newAsset.name = null;
    this.newAsset.manufacturer = null;
    this.showRoomCreation = false;
    this.selectedRoom = null;
    this.room = null;
    this.roomName = null;
    this.activeStep = DeviceWizardSteps.SelectRoom;
  }

  private goBackFromRoomSelection() {
    if (this.showRoomCreation) {
      if (this.rooms.length) {
        this.showRoomCreation = false;
        this.roomName = '';
        return;
      }
      this.roomName = '';
      this.activeStep = DeviceWizardSteps.DeviceFound;
      return;
    }
    this.room = null;
    this.$emit('reload:site', true)
    this.activeStep = DeviceWizardSteps.DeviceFound
  }

  private goBackOrExit() {
    if (this.$route.params.siteId && this.devices.length <= 1){
      this.$router.push({name : 'devices'})
      return;
    }
    this.saveDevices();
    this.$emit('go-to:site-wizard');
  }

  private getMonitoringPointNameHint(type: string) {
    switch (type) {
      case 'refrigerator': return `This is a friendly name used to identify the location of the sensor in your refrigerator, e.g., 'Rear left'.`;
      case 'freezer': return `This is a friendly name used to identify the location of the sensor in your freezer, e.g., 'Rear left'.`;
      case 'room': return `This is a friendly name used to identify the location of your ambient temperature sensor.`;
    }
  }


  private selectInputMode(mode: number) {
    this.selectedMode = mode;
    if (mode === InputModes.QrScan) {
      this.loadingCamera = true;
    }
  }

  private decodeBarcodeResult(fullResult: string) {
    let result;
    if (fullResult.includes('SN=')) {
      result = {device_id: fullResult.split('SN=')[1]};
    } else if (fullResult.includes(',')) {
      const networkIdArr = fullResult.split(',');
      result = {network_id: networkIdArr[networkIdArr.length - 1], device_id: networkIdArr[1]};
    } else {
      result = {network_id: fullResult};
    }
    return result;
  }

  private async displayResult(fullResult: string) {
    this.selectedMode = InputModes.KeyIn;
    this.loadingScanResult = true;
    try {
      // const barcodeResult = await DeviceService.scanDevice(fullResult);
      const barcodeResult = this.decodeBarcodeResult(fullResult);
      if (barcodeResult) {
        this.eui = barcodeResult.network_id || barcodeResult.device_id || null;
      }
    } catch (e) {
      this.showToastError(`The QR scan failed, try entering the DevEUI manually if the problem persists.`);
      throw e;
    } finally {
      this.loadingScanResult = false;
    }
  }

  private async searchDevices() {
    this.searching = true
    if (!this.eui) {
      throw new Error('Missing EUI property')
    }
    const existingDevice = this.devices.find((d) => d.eui.toLowerCase() === this.eui?.toLowerCase() || this.eui?.toLowerCase() === d.device_id.toLowerCase());
    if (existingDevice) {
      Vue.set(this, 'device', existingDevice);
      this.foundWithBarcode = false;
      this.searching = false;
      this.eui = this.selectedDevice = existingDevice.eui;
      this.activeStep = DeviceWizardSteps.DeviceFound;
      return;
    }
    try {
      const devices = (await DeviceService.findDispatchedDevice(this.eui!.toLowerCase()));
      this.device = devices.length > 1 ? null : devices[0];
      if (this.device) {
        this.selectedDevice = this.eui = this.device.eui;
      }
    } finally {
      this.activeStep = DeviceWizardSteps.ConfirmAssign;
      this.searching = false;
    }
  }

  private async assignDevice() {
    this.assigning = true;
    try {
      await DeviceService.addDeviceToAccount(this.eui!)
      this.foundWithBarcode = true;
    } catch (e) {
      this.showToastError(`Something went wrong adding the device to your account, visit the knowledge base for more help.`)
      throw e;
    } finally {
      this.assigning = false;
      this.activeStep = DeviceWizardSteps.DeviceFound;
    }
  }

  private async loadCamera(promise: Promise<any>) {
    this.loadingCamera = true;

    try {
      await promise;
    } catch (error) {
      const err = error as Error;
      if (err.name === 'NotAllowedError') {
        this.showToastError("You need to grant camera access permission");
      } else if (err.name === 'NotFoundError') {
        this.showToastError("No camera on this device");
      } else if (err.name === 'NotSupportedError') {
        this.showToastError("Secure context required (HTTPS, localhost)");
      } else if (err.name === 'NotReadableError') {
        this.showToastError("Is the camera already in use?");
      } else if (err.name === 'OverconstrainedError') {
        this.showToastError("Installed cameras are not suitable");
      } else if (err.name === 'StreamApiNotSupportedError') {
        this.showToastError("Stream API is not supported in this browser");
      } else if (err.name === 'InsecureContextError') {
        this.showToastError('Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.');
      } else {
        this.showToastError(`Camera error (${err.name})`);
      }
    } finally {
      this.loadingCamera = false;
    }
  }

  private onError(e: any) {
    this.showToastError(e.message)
    throw e;
  }

  private async setToScanAndStepForward() {
    this.eui = null
    this.activeStep = DeviceWizardSteps.ScanBarcode;
    this.selectedMode = InputModes.QrScan;
  }

  private get searchDisabled() {
    return !(!this.searching && !!this.eui && !this.loadingCamera && !this.loadingScanResult);
  }

  private get sensorDevEui(): string {
    return require('@/assets/setup/sensor/dev_eui.png')
  }

  private get sensorQrCode(): string {
    return require('@/assets/setup/sensor/qr_code.png')
  }

  private get windowHeight() {
    return window.innerHeight;
  }

  private set assetName(name: string) {
    Vue.set(this.newAsset, 'name', name)
  }

  private get assetName() {
    if (this.newAsset.name) {
      return this.newAsset.name;
    } else {
      return '';
    }
  }

  private set assetManufacturer(manufacturer: string) {
    Vue.set(this.newAsset, 'manufacturer', manufacturer)
  }

  private get assetManufacturer() {
    if (this.newAsset.manufacturer) {
      return this.newAsset.manufacturer;
    } else {
      return '';
    }
  }

  private get monitoringPointDisabled() {
    if (this.loadingMonitoringPoint) {
      return true;
    }
    return !(this.monitoringPoint && this.configuration.minTemp && this.configuration.maxTemp) || !this.validTemps;
  }

  private get roomDisabled() {
    if (this.loadingRoom) {
      return true;
    }
    if (this.showRoomCreation) {
      return !this.roomName;
    } else {
      return !this.selectedRoom;
    }
  }

  private get disabled() {
    if (this.loadingAsset) {
      return true;
    }
    if ((this.selectedAssetType === 'freezer' || this.selectedAssetType === 'refrigerator')) {
      if (this.showAssetCreation) {
        return !this.newAsset.name || !this.newAsset.manufacturer;
      } else {
        return !this.asset;
      }
    } else if (this.selectedAssetType === 'room' && !this.asset || !this.newAsset.name || !this.newAsset.manufacturer) {
      if (this.showAssetCreation) {
        return !this.newAsset.name;
      } else {
        return !this.asset;
      }
    } else return !this.selectedAssetType;
  }

  private get supplierList() {
    return this.suppliers.sort()
  }

  private get devEuiRule() {
    return [((v: string): boolean | string => validation.isEUI64(v) || 'DevEUI is invalid')];
  }
}
