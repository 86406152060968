import { appAxios } from '../app.axios';
import {ApplicationAttribute, ThemeConfiguration} from "@/types";
import store from "@/store";
export class ApplicationService {
  public async getTheme(): Promise<ThemeConfiguration> {
    const accountId = store.getters.accountId || Number(localStorage.getItem('accountId'));
    const url = `/theme/${accountId}`;
    return (await appAxios.get(url)).data;
  }
  public async getAttributes(): Promise<{ application_attributes: ApplicationAttribute[] }> {
    const url = `/application/attributes`;
    return (await appAxios.get(url)).data;
  }
}

export default new ApplicationService();
