










































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import ActionButton from "@/components/ActionButton.vue";
import LoginError from "@/components/LoginError.vue";
@Component({
  components: {LoginError, ActionButton}
})
export default class EmailPrompt extends Vue {
  @Prop() private queryUsername!: string;
  @Prop() private error!: string;
  @Prop() private loading!: boolean;
  private username = '';
  private valid = false;

  @Watch('queryUsername', {immediate: true})
  private onQueryParamUpdate()  {
    this.username = this.queryUsername;
  }
}
