import {Site} from "../types";
import {LocationV2} from "../../../backend/src/daizysdk/src";
import { appAxios } from '../app.axios';
import store from "@/store";

export class SiteService {
  public async getSites(accountId: number, depth = 4, siteId?: string | number, getEvents?: boolean): Promise<Site[]> {
    const queryParams = new URLSearchParams();
    let queryString = '';
    queryParams.append('depth', depth.toString());
    if (siteId) {
      queryParams.append('siteId', siteId.toString());
    }
    if (getEvents) {
      queryParams.append('getEvents', getEvents.toString());
    }
    queryString = `?${queryParams.toString()}`;

    const url = `/projects/${accountId}/sites${queryString}`;

    return (await appAxios.get(url)).data;
  }

  public async createSite(accountId: number, data: { name: string, coords: number[], is_site: boolean }): Promise<LocationV2> {
    const url = `/projects/${accountId}/sites/create`;
    return (await appAxios.post(url, data)).data;
  }

  public async updateSite(locationId: number, data: { attributes?: { key: string, value: string | number }[]; name?: string, coords?: number[], is_site?: boolean; is_monitoring_point?: boolean }) {
    const url = `/projects/${store.getters.accountId}/locations/${locationId}/update`
    return (await appAxios.put(url, data)).data;
  }

  public async deleteSite(locationId: number) {
    const url = `/projects/${store.getters.accountId}/locations/${locationId}/delete`
    return (await appAxios.delete(url)).data;
  }

  public async getAlertsForSite(siteId: number, monitoringPointIds: number[]): Promise<any> {
    const url = `/projects/${store.getters.accountId}/sites/${siteId}/alerts?monitoringPoints=${JSON.stringify(monitoringPointIds)}`;
    return (await  appAxios.get(url)).data;
  }
  public async getAlertsForLocation(locationId: number, timestamp?: { since: number; before: number }, openOnly?: boolean): Promise<any> {
    let url = `/projects/${store.getters.accountId}/locations/${locationId}/alerts/all`;
    if (timestamp) {
      url += `?since=${timestamp.since}&before=${timestamp.before}`
    }
    if (openOnly) {
      url += `?openOnly=true`
    }
    return (await  appAxios.get(url)).data;
  }
  public async getWeatherForSite(siteId: number, timeFilters: {[key: string]: string | number} = {}): Promise<any> {
    const { since, before } = timeFilters;
    const url = `/projects/${store.getters.accountId}/sites/${siteId}/weather?since=${since}&before=${before}`;
    return (await  appAxios.get(url)).data;
  }
}

export default new SiteService();


