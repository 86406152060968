import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import en from 'vuetify/src/locale/en';

Vue.use(Vuetify);

const currentTheme = {
    background: '#343434',
    tertiary: '#555555',
    primary: '#80fffb',
    secondary: '#00AA9E',
    success: '#00b17e',
    info: '#016EA1',
    warning: '#ffbe00',
    error: '#f2243f',
  }

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    options: {
      customProperties: true
    },
    dark: true,
    themes: {
      light: {
        primary: '#32374b',
        secondary: '#f2243f',
        tertiary: '#fafafb',
        success: '#00b17e',
        info: '#016EA1',
        warning: '#ffbe00',
        error: '#f2243f',
      },
      dark: currentTheme,
    },
  },
  lang: {
    locales: { en },
    current: 'en',
  }
})