













import { Component, Vue } from "vue-property-decorator";
import {Getter, Mutation} from "vuex-class";
import Loader from "@/components/Loader.vue";
import { getStage } from "../util";
@Component({
  components: {Loader}
})
export default class KnowledgeBase extends Vue {
  @Getter private appLoading!: boolean;
  @Mutation private toggleLoading!: any;

  private async mounted() {
    if (!Vue.prototype.$embedApplication) {
      this.toggleLoading(true);
    }
  }

  private iframeLoaded() {
    this.toggleLoading(false);
  }

  private getKBURL() {
    const stage = getStage();
    switch (stage) {
      case 'prod':
        return 'https://bellnewprod.service-now.com/uke_kb';
      default:
        return 'https://bellnewdev.service-now.com/uke_kb';
    }
  }

}
